/* Provide sufficient contrast against white background */
/*a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}*/

/*html, body {
    background-color: #263238;
    color: #ffffff;
}
*/

/*body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
}
*/

.app {
    display: flex;
    min-height: 70vh;
    flex-direction: column;
    margin: 0;
}

.page-footer {
    align-self: flex-end;
    width: 100%;
}




.light {
    background-color: #e1e1e1;
    color: #242529;
}

    .light .input-field input {
        color: #242529;
    }

    .light textarea {
        color: #242529;
    }

    .light span {
        color: #242529;
    }

    .light .collection-item {
        background-color: #e1e1e1;
        line-height: 1.0rem;
        padding: 10px 0px;
        margin: 0;
        /*border-bottom: 1px solid #242529;*/
        border: 0px solid #e1e1e1;
    }

    .light .collection {
        border: 0px solid #e1e1e1;
    }

    .light .collapsible-header {
        background-color: #F6F7F3;
    }

    .light .card {
        background-color: #F6F7F3;
    }

    .light .card-content {
        background-color: #F6F7F3;
    }

    .light .card-reveal {
        background-color: #F6F7F3;
    }

    .light input::-webkit-input-placeholder,
    .light textarea::-webkit-input-placeholder {
        color: #ACACAC;
    }

    .light input:-moz-placeholder,
    .light textarea:-moz-placeholder {
        color: #ACACAC;
    }

    .light input::-moz-placeholder,
    .light textarea::-moz-placeholder {
        color: #ACACAC;
    }

    .light input:-ms-input-placeholder,
    .light textarea:-ms-input-placeholder {
        color: #ACACAC;
    }


    .light .home-icon {
        color: #404040;
    }

        .light .home-icon a,
        .light .home-icon a i,
        .light .home-icon a h5,
        .light .home-icon a p {
            color: #404040;
        }

    .light .chip {
        background-color: #f5f5f5;
        color: #263238;
    }

        .light .chip a {
            color: #263238;
        }

        .light .chip:focus {
            outline: none;
            background-color: #bdbdbd;
            color: #263238;
        }

    .light .chips.focus {
        border-bottom: 1px solid #bdbdbd;
        box-shadow: 0 1px 0 0 #bdbdbd;
    }


.dark {
    background-color: #242529;
    color: #e1e1e1;
}

    .dark .input-field input {
        color: #e1e1e1;
    }

    .dark textarea {
        color: #e1e1e1;
    }

    .dark span {
        color: #e1e1e1;
    }

    .dark .collection-item {
        background-color: #242529;
        line-height: 1.0rem;
        padding: 10px 0px;
        margin: 0;
        /*border-bottom: 1px solid #242529;*/
        border: 0px solid #242529;
    }

    .dark .collection {
        border: 0px solid #242529;
    }

    .dark .collapsible-header {
        background-color: #14161F;
    }

    .dark .card {
        background-color: #14161F;
    }

    .dark .card-content {
        background-color: #14161F;
    }

    .dark .card-reveal {
        background-color: #14161F;
    }

    .dark input::-webkit-input-placeholder,
    .dark textarea::-webkit-input-placeholder {
        color: #555;
    }

    .dark input:-moz-placeholder,
    .dark textarea:-moz-placeholder {
        color: #555;
    }

    .dark input::-moz-placeholder,
    .dark textarea::-moz-placeholder {
        color: #555;
    }

    .dark input:-ms-input-placeholder,
    .dark textarea:-ms-input-placeholder {
        color: #555;
    }

    .dark .home-icon {
        color: #919191;
    }

        .dark .home-icon a,
        .dark .home-icon a i,
        .dark .home-icon a h5,
        .dark .home-icon a p {
            color: #919191;
        }

    .dark .chip {
        background-color: #424242;
        color: #cfd8dc;
    }

        .dark .chip a {
            color: #eceff1;
        }

        .dark .chip:focus {
            outline: none;
            background-color: #9e9e9e;
            color: #eceff1;
        }

    .dark .chips.focus {
        border-bottom: 1px solid #9e9e9e;
        box-shadow: 0 1px 0 0 #9e9e9e;
    }


.card .card-action a:not(.btn):not(.btn-large):not(.btn-small):not(.btn-large):not(.btn-floating) {
    color: #6886C5;
    margin-right: 24px;
    transition: color .3s ease;
    text-transform: uppercase;
}

    .card .card-action a:not(.btn):not(.btn-large):not(.btn-small):not(.btn-large):not(.btn-floating):hover {
        color: #68d8a6;
    }


button, .btn, .btn-primary, .chip {
    background-color: #6886C5;
    color: #F6F7F3;
}


.icon-container i {
    font-size: 3em;
    margin-bottom: 5px;
}

.icon-container .icon-preview {
    /*height: 120px;*/
    text-align: center;
    min-height: 120px;
}



/*html, body {
    background-color: #E1e1e1;
    color: #242529;
}*/

/* label color */
/*.input-field input {
    color: #242529;
}

textarea {
    color: #242529;
}*/




/*.modal {
    height: 90% !important;
    width: 90% !important;
    max-height: 90% !important;
}
*/

/*.modal-content {*/
/*height: 90% !important;*/
/*width: 90% !important;*/
/*}*/

/*.slider .slides li img {
    background-size: 100% auto;
    background-repeat: no-repeat;
}
*/
